@font-face {
    font-family: "interBlack";
    src: url("./assets/fonts/Inter-Black.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interBold";
    src: url("./assets/fonts/Inter-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interExtraBold";
    src: url("./assets/fonts/Inter-ExtraBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interExtraLight";
    src: url("./assets/fonts/Inter-ExtraLight.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interLight";
    src: url("./assets/fonts/Inter-Light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interMedium";
    src: url("./assets/fonts/Inter-Medium.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interRegular";
    src: url("./assets/fonts/Inter-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interSemiBold";
    src: url("./assets/fonts/Inter-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "interThin";
    src: url("./assets/fonts/Inter-Thin.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: "copperplateGothicBoldRegular";
    src: url("./assets/fonts/Copperplate\ Gothic\ Bold\ Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

$app-name: "Trashking";

$dark-color-main: #0A4000;
$dark-color-app: #002121;
$light-color-main: #2AFF04;
$light-color-menu: #006837;
$dark-color-menu: #126603;
$control-color: #C8D6C5;
//$control-color: #7D9B78;
$nowaste-logo-color: #49a550;
$light-backgrounds: #D9D9D8;
$splash-white: #F9FDF9;
$white: #FFFFFF;
$error: #FF0404;

:export{
    darkColorMain: $dark-color-main;
    darkColorMain: $dark-color-app;
    lightColorMain: $light-color-main;
    lighColorMenu: $light-color-menu;
    darkColorMenu: $dark-color-menu;
    controlColor: $control-color;
    lightBackgrounds: $light-backgrounds;
    whiteColor: $white;
    erro: $error;
}

//Trashking project on exseniarch@gmail.com Google account
$google-api-key: "AIzaSyDRi5AoD_8Q7vGrez0Ow_fyl2iim14CgkY";