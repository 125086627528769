@import "../../globalVars.module.scss";

.modalContainer {
  position: absolute;
  z-index: 10000;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0px;
  color: $dark-color-main;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: interRegular;
  font-size: 14px;
  cursor: pointer;
  /* Smooth transition from a greyish to a less greyish color */
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  /* Apply a backdrop filter for the glass effect */
  backdrop-filter: blur(10px);
}

.alertContainer {
  width: calc(100% - 30px);
  border-radius: 10px;
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  max-height: 50%;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  border: 0px solid black;
  opacity: 1;

  .dataContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;    
    width: 100%;

    .imageContainer{      
      width: 64px;
      margin: 5px 0px 0px 15px;
    }

    .message{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0px 35px 0px 5px;
    }
  }

  .progressContainer{
    display: flex;
    flex-direction: row;    
    width: 100%; 
    justify-content: flex-start;    
    align-items: flex-start;

    .progressBar{
      display: flex;
      flex-direction: row;
      height: 5px;
    }
  }
}

.separator{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
}