.container{
    display: flex;
    flex-direction: column;
    width: 100%;   
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    overflow-y: scroll;
    padding-bottom: 100px;
}
