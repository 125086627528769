@import "../../globalVars.module.scss";

$dropdownHeight: 150px;

.dropDownContainer {
    display: flex;
    height: 50px;
    width: 80px;
    border: 1px solid $dark-color-main;
    color: $dark-color-main;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "interBold";
    background-color: $control-color;
    color: $dark-color-main;
    cursor: pointer;
    opacity: 1;
    transition: opacity 100ms ease;

    .buttonContainer {
        padding: 0px 10px;
        height: 32px;
    }

    .button {
        height: 32px;
    }

    .listContainer {
        display: flex;
        flex-direction: column;
        z-index: 1000 !important;
        background-color: $control-color;        
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;
        overflow-x: hidden;

        left: 0px;
        position: absolute;
        width: 250px;
        border: 1px solid $dark-color-main;

        .list {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: 150px;
        }

        .listFaderTop{
            position: absolute;
            top: 0px;
            width: 100%;
            height: 45%;            
            background: linear-gradient(180deg, rgba(125,155,119,1) 80%, rgba(125,155,119,0) 100%);
        }

        .listFaderBottom{
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 45%;            
            background: linear-gradient(0deg, rgba(125,155,119,1) 80%, rgba(125,155,119,0) 100%);
        }
    }

    .items {
        width: 100%;
        padding: 2px 10px;
        color: $dark-color-main;
        border-radius: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .items:hover {
        color: $light-color-main;
        border: 1px solid $dark-color-main;
    }
}

input {
    color: $dark-color-main;
}

.dropDownContainer:hover {
    opacity: 1;
}

.dropDownContainer:active {
    opacity: 1;
}

input:focus {
    outline: none;
}