@import "../../../globalVars.module.scss";

.displayingCalendar{
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.displayingClock{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10xp;
    width: 90%;
}

.images{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;    
    width: 241px;
    height: 100px;

    .imagePlaceholder{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
    }
}

.stackInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .totalWeight{    
    }

    .categoryName{
    }
}