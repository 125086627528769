@import "../../globalVars.module.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: column;    
    align-items: center;
    justify-content: center;
    
    .border{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $dark-color-app;
        border-radius: 5px;
        padding: 20px;
        width: calc(100% - 20px);
        height: 100%;
        background-color: $light-backgrounds;
    }
    
    .titleContainer{
        position: absolute;
        top: -15px;
        left: 10px;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;        
        background-color: $light-backgrounds;
        padding: 0px 5px;
    }
    
    .title{        
        display: flex;
        justify-content: center;
        width: 100%;
        font-family: interBold;
        font-size: 20px;
        text-align: center;
    }
}
