@import "../../../globalVars.module.scss";

.trashkingContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //background: fixed top no-repeat url('../../assets/photos/Opt4K\ Recycling\ Children.jpg');
    background-size: cover;
}

.SampleMap {
    width: 100vw;
    height: 100vh;
    background: fixed bottom no-repeat url('../../../assets/photos/map.jpg');
    background-size: 'cover';
}

.headerPanel {
    position: absolute;
    top: 0px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //background-color: white;
    //background: rgba(255,255,255, 0.7);
    width: 100%;
    height: 80px;
    box-shadow: #00000088 0px 2px 2px;
    /* Smooth transition from a greyish to a less greyish color */
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    /* Apply a backdrop filter for the glass effect */
    backdrop-filter: blur(10px);


    .headerInnerPanel {
        position: absolute;
        top: 0px;
        z-index: 100;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        background: linear-gradient(0deg,
                #254220 0%,
                #D9D9D9 50%,
                #254220 100%);
        width: 100%;
        height: 80px;

        .text {
            font-family: interExtraBold;
            font-size: 22px;
            padding-left: 30px;
            color: #49a550; //#006837;
            text-shadow: white 0px -1px 1px;
        }

        .logo {
            height: 64px;
            padding-right: 30px;
            color: white;
        }
    }
}

.notificationsRow {
    position: absolute;
    top: 110px;
    right: 30px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 50px;

    .counter {
        cursor: pointer;
        // position: absolute;            
        // z-index: 101;
        width: 42px;
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: interExtraBold;
        font-size: 14px;
        ;
        color: #2AFF04;
        background: bottom no-repeat url('../../../assets/icons/bell.svg');
        background-size: contain;
    }
}

.footerPanel {
    position: absolute;
    bottom: 22px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 128px;

    .toolsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 90%;

        .splitter {
            width: 10px;
        }
    }
}

.contactContainer {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.trashkingShiny {
    width: 490px;

    .left {
        text-align: left;
    }

    .right {
        text-align: right;
    }
}

.description {
    font-size: 32px;
    text-align: center;
    line-height: 55px;
    text-shadow: #FFFFFF 1px 0px 5px;
}

.list {
    width: 400px;
    font-family: "interRegular";
    font-size: 24px;
    text-align: left;
}

.inlineBold {
    color: #0A4000;
    font-family: "interBold";
}