@import "../../globalVars.module.scss";

.dialogContainer {
    position: fixed;
    z-index: 10000;
    display: flex;
    height: 100vh;
    width: 100vw;
    top: 0px;
    color: $dark-color-main;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "interBold";
    background-color: $control-color;
    cursor: pointer;

    .frame{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 90%;
        height: fit-content;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #0A4000;
        border-radius: 20px;
        background-color: #EEEBDF;
        opacity: 1;

        .titleBar{
            display: flex;
            flex-direction: row;
            padding: 10px 0px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            font-family: interLight;
            font-size: 16px;
            color: #002121;

            .title{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding: 0px 15px;
                
            }

            .closeButton{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 0px 15px;
            }
        }

        .body{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 5px 0px;
            width: calc(100% - 30px);
            font-family: interSemiBold;
            font-size: 16px;
            color: #002121;
        }

        .description{
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 5px 0px;
            align-items: center;
            justify-content: space-around;
            font-family: interLight;
            font-size: 16px;
            color: #002121;            
        }

        .spacer{
            display: flex;
            flex-direction: row;
            padding: 10px 0px;
            height: 40px;
        }

        .buttons{
            display: flex;
            flex-direction: row;
            position: absolute;
            z-index: 100;
            bottom: -25px;
        }

        .buttonSpacer{
            display: flex;
            flex-direction: row;
            width: 15px;            
        }
    }
}
