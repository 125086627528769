@import "../../../globalVars.module.scss";

.trashkingContainer {    
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //background: fixed top no-repeat url('../../assets/photos/Opt4K\ Recycling\ Children.jpg');
    background-size: cover;
}

.contactContainer{
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.trashkingShiny{
    width: 490px;
    .left{
        text-align: left;
    }
    .right{
        text-align: right;
    }    
}

.description{
    font-size: 32px;
    text-align: center;
    line-height: 55px;
    text-shadow: #FFFFFF 1px  0px 5px;
}

.list{
    width: 400px;
    font-family: "interRegular";
    font-size: 24px;
    text-align: left;
}

.inlineBold{
    color: #0A4000;
    font-family: "interBold";
}

.marker{
    display: flex;
    flex-direction: column;
    height: 64px;
    background: fixed center no-repeat url('../../../assets/icons/mapPin.svg');
    color: white;
    font-size: 21px;
}

.markerLabel{
    color: white;
    font-size: 28px;
}

.alertBox{
    display: flex;
    border: 1px solid #09DF09;
    background-color: #09DF09A8;
    position: absolute;
    z-index: 50;
    width: calc(100% - 30px);
    height: 90px;
    top: 50%;
    left: 15px;    
    align-items: center;
    justify-content: center;
    font-family: interRegular;
    font-size: 18px;
    border-radius: 20px;
    align-self: center;
    justify-self: center;    
}

.overallDataBox{
    display: flex;
    flex-direction: row;
    border: 1px solid #099B09FE;
    background-color: #0a9a0ab3;
    position: absolute;
    z-index: 10000;
    width: 120px;
    height: 45px;    
    align-items: center;
    justify-content: space-between;
    font-family: interRegular;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid #CDF9CD;
    align-self: center;
    justify-self: center;
    color: whitesmoke;
}

.detailDataBox{
    display: flex;
    flex-direction: row;
    // border: 1px solid #09DF09FE;
    background:  left center no-repeat url('../../../assets/icons/stackPin.png');
    background-size: 64px;
    position: absolute;
    z-index: 10000;
    width: 70px;
    height: 64px;
    align-items: flex-end;
    justify-content: flex-end;
    color: whitesmoke;//ToDO convert to #FF0404 on production map;    
    font-family: interSemiBold;
    font-size: 12px;
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    top: -70px;
    left: -32px;
}

.package{
    height: 64px;
}

.packageInfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    align-items: flex-start;
    justify-content: flex-start;

    .packageWeight{
 font-family: interBold;
 font-size: 12px;
    }

    .packageFirstRedemption{
        font-family: interRegular;
        font-size: 10px;
    }
}