@import "../../globalVars.module.scss";

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loadingText{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: #29983a;
        padding: 15px;
        font-family: interExtraBold;
        font-size: 21px;
        letter-spacing: 5px;
        animation: colors 3s infinite;        
    }
}

@keyframes colors{
    0% {color: #29983a;}
    25% {color: #29983a8d;}
    50% {color: #29983a;}
    75% {color: #29983a4e;}
    100% {color: #29983a;}
}

.buttonContainer:hover{
    opacity: 1;
}

.buttonContainer:active{
    opacity: 0.5;
}