@import "../../globalVars.module.scss";

.container {
    display: flex;
    //flex-direction: column;    
    //align-items: center;
    //justify-content: center;
    
    .title{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px;
        font-family: interLight;
        font-size: 12px;
        color: $dark-color-app;
    }

    .titleDescription{
        // display: flex;
        // flex-direction: row;
        // align-items: flex-start;
        // justify-content: flex-start;
        // padding: 0px;
        font-family: interLight;
        font-size: 10px;
        color: grey;
    }
    
    .errorMessage{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        padding: 0px;
        font-family: interregular;
        font-size: 10px;
        color: $error;
    }    
}
