@import "../../../../globalVars.module.scss";

.toggleBox {
    width: 90%;
    height: 32px;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.listingType {
    font-family: interExtraBold;
    font-size: 24px;
    color: $dark-color-menu;
}

.programContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
}

.program {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 70px;
    align-items: center;
    justify-content: space-between;

    .category {
        font-family: interBold;
        font-size: 16px;
    }

    .location {
        font-family: interLight;
        font-size: 16px;
    }

    .date {
        font-family: interBold;
        font-size: 16px;
    }

    .period {
        font-family: interLight;
        font-size: 16px;
    }

    .categoryConsumer {
        font-family: interSemiBold;
        font-size: 14px;
    }

    .volumeConsumer {
        font-family: interLight;
        font-size: 12px;
    }

    .collectorConsumer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: interRegular;
        font-size: 12px;
    }

    .dateConsumer {
        font-family: interSemiBold;
        font-size: 12px;
    }

    .periodConsumer {
        font-family: interLight;
        font-size: 12px;
    }


    .buttonCollector {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        width: 100px;
        height: 40px;
        border: 1px solid #FF0404;
        border-radius: 5px;
        color: #FF0404;
        align-items: center;
        justify-content: space-around;
        padding: 0px 3px;
    }

    .buttonConsumer {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        width: 100px;
        height: 40px;
        border: 1px solid #FF0404;
        border-radius: 5px;
        color: #FF0404;
        align-items: center;
        justify-content: center;
        padding: 0px 3px;
        font-family: interSemiBold;
        font-size: 14px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
}

.separator {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 1px;
    background: linear-gradient(90deg, rgba(10, 64, 0, 0) 0%, rgba(10, 64, 0, 1) 50%, rgba(10, 64, 0, 0) 100%);
}

.footer {
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    padding: 0xp 30px;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEBDF;
}

.info {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100%;
    align-items: center;

    .label {
        font-family: interRegular;
        font-size: 18px;
    }

    .data {
        font-family: interBold;
        font-size: 18px;
    }
}

.images {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 241px;
    height: 100px;

    .imagePlaceholder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
    }

    .addImage {}
}