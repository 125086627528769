
.toggleContainer {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    transition: opacity 300ms ease;

    .toggleText{
        padding: 7px;        
    }

    .toggleFrame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        border: 5px solid #a9a9a9;
        
        width: calc(height * 2);
        border-radius: calc(height/2);

        .toggleCircle {
            position: absolute;
            border: 1px solid;            
            width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.toggleContainer:hover{
    opacity: 1;
}

.toggleContainer:active{
    opacity: 0.5;
}