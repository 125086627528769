@import "../../../globalVars.module.scss";

.images{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;    
    width: 241px;
    height: 100px;

    .imagePlaceholder{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
    }

    .addImage{

    }
}

.googleButton{
    cursor: pointer;
}