@import "../../../globalVars.module.scss";

.menuContainer {
    position: relative;
    display: flex;
    height: calc(100vh - 22px);
    width: calc(100vw - 18px);    
    color: $dark-color-main;    
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "interBold";
    background-color: #D9D9D9;
    border: 1px solid #126603;
    border-radius: 20px;
    cursor: pointer;
    // opacity: 0.9;
    // transition: opacity 300ms ease;
}

.closeButtonContainer{    
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    padding: 15px;
}

.menuItems{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60%;
    font-family: interLight;
    font-size: 18px;
    color: #006837;
}

.userTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: copperplateGothicBoldRegular;
    font-size: 20px;
}

.imageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.signupButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 42px;
    background-color: #002121;
    color: #DBEBD8;
}

.loginButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 42px;
    background-color: #EEEBDF;
    border: 1px solid #002121;
    color: #002121;
}