@import "../../globalVars.module.scss";

.buttonContainer {
    
    display: flex;
    height: 50px;
    width: 200px;
    border: 1px solid $dark-color-main;
    color: $dark-color-main;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "interExtraBold";
    background-color: $control-color;
    cursor: pointer;
    opacity: 1;
    transition: opacity 300ms ease;
}

.buttonContainer:hover{
    opacity: 1;
}

.buttonContainer:active{
    opacity: 0.5;
}