@import "../../globalVars.module.scss";

.modal{
    background-color: var($light-color-main);
}

.screenContainer {    
    position: relative;
    z-index: 10;
    display: flex;
    height: calc(100vh - 22px);
    width: calc(100vw - 18px);    
    color: $dark-color-main;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "interBold";
    background-color: #D9D9D9;
    border: 1px solid #126603;
    border-radius: 20px;   

    .screenHeaderContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 130px;
        justify-content: space-between;
        align-items: center;
    
        .closeContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            align-self: flex-end;
            padding: 15px 0px;
        }
    }
}

.pageContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    color: $dark-color-main;    
    align-items: center;
    font-family: "interBold";
    background-color: #EEEBDF;    

    .pageHeaderContainer{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 56px;
        padding: 40px 0px 20px 0px;
        justify-content: space-around;
        align-items: center;
        
        .backContainer {
            display: flex;
            flex-direction: row;            
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: flex-end;            
            padding: 15px 0px;            
        }

        .emptyCell{
            display: flex;
            flex-direction: row;            
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: flex-end;                   
            padding: 15px 0px;            
        }
    }
}

.screenContainerLousy {    
    position: relative;
    display: flex;
    height: calc(100vh - 22px);
    width: calc(100vw - 18px);    
    color: $dark-color-main;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "interBold";
        
    .screenHeaderContainerLousy{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 130px;
        justify-content: space-between;
        align-items: center;
    
        .closeContainerLousy {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            align-self: flex-end;
            padding: 15px 0px;
        }
    }
}

.mixedDateControls{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.timePicker{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid red;
    align-items: center;
    justify-content: center;
}

.smallLinkText{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    font-family: interLight;
    font-size: 12px;
    color: $dark-color-app;
}

.listButtons{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 50px;
    padding: 5px 0px;
    align-items: center;
    justify-content: flex-end;
}

.separator{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20px;
}

.separatorHalf{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10px;
}

.listSeparator{
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 1px;
    background: linear-gradient(90deg, rgba(10,64,0,0) 0%, rgba(10,64,0,1) 50%, rgba(10,64,0,0) 100%);
}

.listSeparatorLeft{
    display: flex;
    flex-direction: row;
    min-width: 30%;
    margin: 0px 10px;
    height: 1px;
    background: linear-gradient(90deg, rgba(10,64,0,0) 0%, rgba(10,64,0,1) 50%);
}

.listSeparatorRight{
    display: flex;
    flex-direction: row;
    min-width: 30%;
    margin: 0px 10px;
    height: 1px;
    background: linear-gradient(90deg, rgba(10,64,0,1) 50%, rgba(10,64,0,0) 100%);
}

.listHeader{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;

    .headerItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: interRegular;        
    }

    .headerItemClicked{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: interBold;
    }
}

.listHeaderSeparator{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 1px;
    background: #7D9B78;
}

.splitter{
    display: flex;
    flex-direction: row;
    width: 10px;
    height: 100%;
}
