@import "../../../../globalVars.module.scss";

.description {
    width: 90%;
    height: 70px;
    padding: 0px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //background-color: blanchedalmond;

    .headerRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}

.listingType {
    font-family: interExtraBold;
    font-size: 24px;
    color: $dark-color-menu;
}

.programContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
}

.program {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 70px;
    align-items: center;
    justify-content: space-between;

    .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .category {
        font-family: interBold;
        font-size: 16px;
    }

    .location {
        font-family: interLight;
        font-size: 16px;
    }

    .date {
        font-family: interBold;
        font-size: 16px;
    }

    .period {
        font-family: interLight;
        font-size: 16px;
    }

    .button {
        display: flex;
        flex-direction: row;
        width: 100px;
        height: 40px;
        border: 1px solid #FF0404;
        border-radius: 5px;
        color: #FF0404;
        align-items: center;
        justify-content: center;
        padding: 0px 3px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
}

.separator {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 1px;
    background: linear-gradient(90deg, rgba(10, 64, 0, 0) 0%, rgba(10, 64, 0, 1) 50%, rgba(10, 64, 0, 0) 100%);
}

.footer {
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    padding: 0xp 30px;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEBDF;
}

.info {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .stackDetails {
        display: flex;
        flex-direction: column;
        height: 60px;
        width: calc(100% - 160px);
        justify-content: space-around;

        .detailRow{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.label {
    font-family: interRegular;
    font-size: 18px;
}

.data {
    font-family: interBold;
    font-size: 18px;
}

.dataSmall {
    font-family: interRegular;
    font-size: 16px;
}

.dataTiny {
    font-family: interRegular;
    font-size: 12px;
}

.stackData {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.name {
    display: flex;
    font-family: interLight;
    font-size: 18px;
    flex-direction: row;
    align-items: flex-start;
}

.deliveryCode {
    font-family: interLight;
    font-size: 16px;
}


.images {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 241px;
    height: 100px;

    .imagePlaceholder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
    }

    .addImage {}
}