@import "../../../globalVars.module.scss";

.images {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-wrap: normal;
    overflow-y: scroll;
    width: 241px;
    height: 100px;
        
    .imagePlaceholder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
        margin: 10px 5px;
        position: relative;

        img {
            border-radius: 5px;
            height: 100%;
            max-width: 100%;
            object-fit: scale-down;
        }
    }
}