@import "../../globalVars.module.scss";

.modalContainer {
    position: absolute;
    z-index: 100;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0px;
    color: $dark-color-main;    
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: interBold;
    background-color: $control-color;
    cursor: pointer;
    opacity: 0.95;
    // background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    // backdrop-filter: blur(10px);
}

.modalContainerGlassy {
    position: absolute;
    z-index: 100;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0px;
    color: $dark-color-main;    
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: interBold;
    //background-color: $control-color;
    cursor: pointer;
    
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
}
