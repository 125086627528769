@import "../../globalVars.module.scss";

.modalContainer {
  position: absolute;
  z-index: 10000;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0px;
  color: $dark-color-main;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: interRegular;
  cursor: pointer;
  /* Smooth transition from a greyish to a less greyish color */
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  /* Apply a backdrop filter for the glass effect */
  backdrop-filter: blur(10px);
}

.infoContainer {
  //width: calc(100% - 30px);
  max-width: calc(100% - 30px);
  border-radius: 15px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  //min-height: 50px;
  //max-height: 50%;
  align-items: center;
  justify-content: space-between;
  background-color: $light-backgrounds;
  border: 1px solid $nowaste-logo-color;
  opacity: 1;
  
  .headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    
    .titleContainer {
      display: flex;
      font-size: 10px;
    }
    
    .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      margin: 5px;
      img{
        width: 16px;
      }
    }
  }
  
  .dataContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
    text-align: center;
    width: 100%;

    .message{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: justify;
      font-size: 12px;
      width: 100%;
      margin: 0px 10px 10px 10px;
    }
  }

  .progressContainer{
    display: flex;
    flex-direction: row;    
    width: 100%; 
    justify-content: flex-start;    
    align-items: flex-start;

    .progressBar{
      display: flex;
      flex-direction: row;
      height: 5px;
    }
  }
}

.separator{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
}