@import "../../../globalVars.module.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 50px;
    width: 150px;    
    color: $dark-color-main;
    align-items: center;
    justify-content: space-between;
    
    .microTitle{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-family: interRegular;
        font-size: 18px;
        text-align: left;        
    }
    
    .title{
        position: absolute;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        font-family: interExtraBold;
        font-size: 24px;
        text-align: right;
        top: calc(50% - 12px);        
    }
    
    .nanoTitle{
        display: flex;
        justify-content: center;
        width: 100%;
        font-family: interRegular;
        font-size: 12px;
        text-align: center;        
    }
}
