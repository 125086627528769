@import "../../../globalVars.module.scss";

.swipeContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background: fixed top no-repeat url('../../assets/photos/Opt4K\ Recycling\ Children.jpg');
    //background-size: cover;
}

.slideContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    
    //background-color: $dark-color-app;
    color: darkgray;
}

.swipeableFooter{
    height: 50px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.swipe{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $dark-color-main;
}

.activeSwipe{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $light-color-main;
}

.choices{
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 30px;
    align-items: center;
    justify-content: space-between;

    .sides{
        width: 30px;
    }
}

.checkLanded{
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 50px;
    align-items: center;
    font-family: interRegular;
    justify-content: center;    
}
