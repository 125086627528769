@import "../../globalVars.module.scss";

.modalContainer {
  position: absolute;
  z-index: 10000;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0px;
  color: $dark-color-main;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: interRegular;
  font-size: 14px;
  cursor: pointer;
  /* Smooth transition from a greyish to a less greyish color */
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  /* Apply a backdrop filter for the glass effect */
  backdrop-filter: blur(10px);
}

.loadingContainer {
  width: calc(100% - 30px);
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  justify-content: center;
  opacity: 1;

  .imageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 128px;
    margin: 0px 5px;
  }

  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px 35px 0px 5px;
  }

  .progressContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    .progressBar {
      display: flex;
      flex-direction: row;
      height: 5px;
    }
  }
}