@import "../../globalVars.module.scss";

.textBoxContainer {
    display: flex;
    height: 50px;
    width: 200px;
    border: 1px solid $dark-color-main;
    color: $dark-color-main;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "interBold";
    background-color: $control-color;
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 300ms ease;
}

.textBoxContainer:hover {
    opacity: 1;
}

.textBoxContainer:active {
    opacity: 0.5;
}

input:focus {
    outline: none;    
}
