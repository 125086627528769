@import "../../../../globalVars.module.scss";

.consumerInfo{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .consumerFullName{
        display: flex;
        font-family: 'interSemiBold';
        font-size: 18px;        
    }

    .consumerPhoto{
        display: flex;
        width: 92px;

        .img {
            width: 92px;
            height: 92px;
            border-radius: 50%;
        }
    }    
}

.location{
    display: flex;
    flex-direction: column;
    width: 100%;

    .label{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-family: 'interLight';
        font-size: 16px;
    }

    .address{
        display: flex;
        flex-direction: row;
        width: 100%;
        font-family: 'interSemiBold';
        font-size: 16px;
        text-align: center;
    }
}

.deliveryCode{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-around;
    padding: 7px 0px;
    border: 1px dotted darkgray;
    border-radius: 10px;
    background-color: rgba($light-color-menu, .2);

    .label{        
        display: flex;
        flex-direction: column;
        font-family: 'interSemiBold';
        font-size: 16px;
        text-align: center;
    }

    .code{
        display: flex;
        flex-direction: column;
        font-family: 'interExtraBold';
        font-size: 16px;
        text-align: center;
    }
}

.other{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .label{        
        display: flex;
        flex-direction: column;
        font-family: 'interLight';
        font-size: 16px;
        text-align: center;
    }

    .data{
        display: flex;
        flex-direction: column;
        font-family: 'interBold';
        font-size: 16px;
        text-align: center;
    }
}

.images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    border: 1px dotted $dark-color-main;
    border-radius: 5px;
    width: 100%;
    height: 90px;

    .noImage{
        display: block;
        flex-direction: column;
        width: 64px;
        height: 64px;
        min-width: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
        margin: 10px 5px;
        background: no-repeat center center url('../../../../assets/icons/gallery.png');
        background-color: #D9D9D9;
    }
    
    .imagePlaceholder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 1px dotted #0A4000;
        border-radius: 5px;
        margin: 10px 5px;
        position: relative;

        img {
            border-radius: 5px;
            height: 64px;
            width: 64px;
            object-fit: scale-down;
        }
    }
}