@import "../../../globalVars.module.scss";

.splashContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $nowaste-logo-color;
    //background: fixed top no-repeat url('../../assets/photos/Opt4K\ Recycling\ Children.jpg');
    //background-size: cover;
}

.mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        height: 128px;
    }

    .nowaste {
        font-family: interSemiBold;
        font-size: 22px;
        color: $splash-white;
        padding: 10px 0px 5px 0px;
    }

    .title {
        font-family: interSemiBold;
        font-size: 18px;
        color: $splash-white;
        padding: 5px 0px;
    }
}

.company{
    font-family: interRegular;
    font-size: 18px;
    color: $splash-white;
    padding: 10px 0px;
}

.crispySymbol{
    font-family: interThin;
    font-size: 12px;
}